<template>
  <main class="page-container" id="sme-we">
    <div class="page-header" style="padding: 55px 0 30px 0">
      <div class="page-component">
        <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
        <div v-if="isMobile" class="box_select">
          <button type="button" id="select_btn" @click="click">소상공인 포스트</button>
            <ul id="select_list">
              <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
            </ul>
        </div>
        <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
        <!-- <div v-if="isMobile" class="section-notice-arrow">
          <button type="button" class="btn-arrow disabled">
            <i class="icon-arrow-prev">이전 페이지</i>
          </button>
          <a href="/playground?ctgrCode=1001001" class="btn-arrow">
            <i class="icon-arrow-next">다음 페이지</i>
          </a>
        </div> -->
        <div class="page-header-title">
          <h2 class="title">소상공인 포스트</h2>
          <p class="description">요즘 뜨는 뉴스, 마케팅, 지원사업 정보 찾기 어려우셨죠? '소포'는 늘 바쁘신 우리 소상공인에게 유용한 정보만을 엄선하여 드리는 서비스예요.</p>
        </div>
      </div>
    </div>
    <div class="page-body" data-fill="true">
      <div class="page-component">
        <div class="post-list-wrapper notice-list-wrapper">
          <div class="list-header">
            <template v-if="isMobile">
              <div class="filter-selector">
                <a href="https://so-po.stibee.com/" target="_blank;" style="display:block;"><span class="btn_sopo_stibee">common/btn_sopo_stibee.svg</span></a>
                <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                  <option value="" selected>최신순</option>
                  <option value="I">인기순</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div  class="header-column">
                <!--<h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>-->
                <a href="https://so-po.stibee.com/" target="_blank;" style="display:block;"><span class="btn_sopo_stibee">common/btn_sopo_stibee.svg</span></a>
              </div>
              <div class="header-column">
                <div class="filter-selector">
                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">
                    <option value="" selected>최신순</option>
                    <option value="I">인기순</option>
                  </select>
                </div>
              </div>
            </template>
          </div>
          <issue-card :items="items" />

          <div v-if="hasMore" class="page-buttons" data-align="center" @click="morePage">
            <button class="btn btn-lg btn-outline-white">
              <span class="text">더보기</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_GET_ISSUE_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck, setPaging, tryResCallback} from '../../../assets/js/utils';
import IssueCard from '../../../components/issue/IssueCard';
import {mapGetters} from 'vuex';
import menus from '../../../assets/js/menus';

export default {
  name: 'Issue',
  components: {
    IssueCard
  },
  data: () => ({
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    items: [],
    tryCount: 0,
    menus: menus.filter(x => (x.code === 'information')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])

  },
  created() {
    this.getIssueList();
  },
  methods: {
    getIssueList(isInit) {
      if(isInit) {
        this.items = [];
        this.pageNo = 1;
      }
      this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        sortCd: this.sortCd
      }).then(res=>{
        tryResCallback(this.tryCount, res, this.getIssueList, this.setIssue);
      }).catch(e=>{
        console.error(e);
      });
    },
    setIssue(res){
      if(lengthCheck(res)){
        this.items = this.items.concat(getItems(res));
      }
      setPaging(this, res);
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getIssueList();
    },
    selectSort() {
      this.getIssueList(true);
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
